import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueHighlightJS from 'vue-highlight.js'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSimpleMarkdown from 'vue-simple-markdown'

// Highlight.js languages (Only required languages)
import python from 'highlight.js/lib/languages/python';
import 'highlight.js/styles/dracula.css';

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueAxios, axios)
Vue.use(VueSimpleMarkdown)
Vue.use(VueHighlightJS, {
	// Register only languages that you want
	languages: {
		python,
	}
});


Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || ''


const env = process.env.NODE_ENV || 'development';

if (env === 'developmentx') {

  Vue.prototype.$keycloak = {
    userName: 'Sunsern',
    createLogoutUrl: () => 'hello.com'
  }

  new Vue({
    router,
    icons,
    render: h => h(App)
  }).$mount('#app')
}
else {
  const tokenInterceptor = () => {
    axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
      return config
    }, error => {
      return Promise.reject(error)
    })
  }

  Vue.use(VueKeyCloak, {
    init: {
      // Use 'login-required' to always require authentication
      // If using 'login-required', there is no need for the router guards in router.js
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
    logout: {
      redirectUri: ''
    },
    config: {
      url: 'https://sso.muic.io/auth',
      clientId: 'cs-python-assn',
      realm: 'IC'
    },
    onReady: (keycloak) => {
      tokenInterceptor()
      new Vue({
        router,
        icons,
        render: h => h(App)
      }).$mount('#app')
    }
  })
}